
.rp-qty-box .rp-cart
a:not([href]):not([tabindex]):focus {
    color: #fff !important;
    text-decoration: none;
}
/*
.rp-qty-box .rp-cart
a:not([href]):not([tabindex]):focus, a:not([href]):not([tabindex]):hover {
    color: #fff !important;
    text-decoration: none;
}
*/

ul li a {
    cursor: pointer;
}

a {
    text-decoration: none !important;
} 

#myModal-img-popup .modal-content {
    width: 100% !important;
    top: 0px !important;
    padding: 0 !important; 
    height: fit-content !important;
    max-height: 100% !important;
}


#myModal-img-popup #carousel-thumbs {
    max-height: 100%;
    overflow: inherit;
}

    #myModal-img-popup #carousel-thumbs .row {
        padding: 0 !important;
    }

    #myModal-img-popup #carousel-thumbs .thumb {
        width: 45% !important;
        min-height: 90px !important;
    }

#myModal-img-popup .modal-dialog.modal-lg {
    top: 0 !important;
    width: 100% !important;
    --bs-modal-width: 75% !important;
}

.make_a_offer {
    text-align: center;
}

    .make_a_offer button {
        height: 29px !important;
        padding: 4px 14px !important;
    }

    .make_a_offer label {
        color: blue;
        cursor: pointer;
    }
