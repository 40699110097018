@font-face {
  font-family: 'wescom';
  src:  url('fonts/wescom.eot?12f6i');
  src:  url('fonts/wescom.eot?12f6i#iefix') format('embedded-opentype'),
    url('fonts/wescom.ttf?12f6i') format('truetype'),
    url('fonts/wescom.woff?12f6i') format('woff'),
    url('fonts/wescom.svg?12f6i#wescom') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="wes-"], [class*=" wes-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'wescom' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.wes-add:before {
  content: "\e929";
  color: #666;
}
.wes-printer:before {
  content: "\e928";
}
.wes-order-number:before {
  content: "\e927";
  color: #fff;
}
.wes-location-selected:before {
  content: "\e926";
  color: #606060;
}
.wes-billing:before {
  content: "\e923";
  color: #999;
}
.wes-clock:before {
  content: "\e924";
  color: #999;
}
.wes-order-summitted:before {
  content: "\e925";
  color: #005be3;
}
.wes-info-cc:before {
  content: "\e919";
  color: #999;
}
.wes-card-cc:before {
  content: "\e91a";
  color: #999;
}
.wes-lock-cc:before {
  content: "\e922";
  color: #999;
}
.wes-checkout-courier:before {
  content: "\e90a";
  color: #666;
}
.wes-checkout-store:before {
  content: "\e90b";
  color: #f82643;
}
.wes-checkout-delivery:before {
  content: "\e918";
  color: #666;
}
.wes-alert:before {
  content: "\e900";
}
.wes-added-cart:before {
  content: "\e901";
}
.wes-card-filter:before {
  content: "\e902";
}
.wes-catalogue:before {
  content: "\e903";
}
.wes-clearance:before {
  content: "\e904";
}
.wes-uniE905:before {
  content: "\e905";
}
.wes-dollar:before {
  content: "\e906";
}
.wes-down-arrow:before {
  content: "\e907";
}
.wes-ebook:before {
  content: "\e908";
}
.wes-fitmydevice:before {
  content: "\e909";
}
.wes-uniE90C:before {
  content: "\e90c";
}
.wes-menu:before {
  content: "\e90d";
}
.wes-my-account:before {
  content: "\e90e";
}
.wes-my-list:before {
  content: "\e90f";
}
.wes-new-product:before {
  content: "\e910";
}
.wes-quick-order:before {
  content: "\e911";
}
.wes-row-filter:before {
  content: "\e912";
}
.wes-shop-brand:before {
  content: "\e913";
}
.wes-shopping-cart:before {
  content: "\e914";
}
.wes-whishlist:before {
  content: "\e915";
}
.wes-cart:before {
  content: "\e916";
}
.wes-search:before {
  content: "\e917";
}
.wes-whishlist1:before {
  content: "\e91b";
}
.wes-buying-option:before {
  content: "\e91c";
}
.wes-call:before {
  content: "\e91d";
}
.wes-filter:before {
  content: "\e91e";
}
.wes-wishlist:before {
  content: "\e91f";
}
.wes-whishlist-active:before {
  content: "\e920";
}
.wes-sortby:before {
  content: "\e921";
}

