CardList.css
.card-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.card {
  background: #f9f9f9;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 1s ease, opacity 1s ease;
}
 
@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateY(-20px) scale(0.95);
  }
  100% {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}

@keyframes slideOut {
  0% {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
  
  25% {
    opacity: 0;
    transform: translateY(5px) scale(0.9);
  }
  50% {
    opacity: 0;
    transform: translateY(15px) scale(0.6);
  }
  100% {
    opacity: 0;
    transform: translateY(20px) scale(0.3);
  } 
}
 
.card-enter {
  animation: slideIn 1s ease forwards;
}

.card-exit {
  animation: slideOut 1s ease forwards;
}

 